import { Component } from '@angular/core';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html'
})

export class FooterComponent {
  Year: number = new Date().getFullYear();
}
