// Core Module
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, NgForm } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

// Config
import { environment } from '../environments/environment';
import { AppConfig } from './app.config';

// Authentication and services
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthInterceptor } from './user/auth.interceptor';
import { UploadInterceptor } from './services/upload.interceptor';

// ApplicationModules
import { AppRoutingModule } from './app-routing.module';
import { UserModule } from './user/user.module';

// Store
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './reducers';
import { AppEffects } from './app.effects';

// Main Component
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { ShellComponent } from './components/shell/shell.component';

import { AccountListComponent } from './components/account-list/account-list.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';

// Component Module
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { LayoutModule } from '@progress/kendo-angular-layout';

// Pages
import { HomePage } from './pages/home/home';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

import { MatProgressBarModule } from '@angular/material/progress-bar';

export function initializeApp(appConfig: AppConfig) {
  return (): Promise<any> => {
        return appConfig.load();
    };
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SidebarComponent,
        TopMenuComponent,
        FooterComponent,
        HomePage,
        AccountListComponent,
        ShellComponent,
        CookiePolicyComponent,
        PagenotfoundComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GoogleMapsModule,
        BrowserAnimationsModule,
        BrowserModule,
        DropDownsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        HttpClientModule,
        OAuthModule.forRoot(),
        UserModule,
        AppRoutingModule,
        GaugesModule,
        LayoutModule,
        MatProgressBarModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([AppEffects]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
    ],
    providers: [
        AppConfig,
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UploadInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: OAuthStorage, useValue: localStorage },
        DeviceDetectorService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
