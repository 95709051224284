// This api will come in the next version

import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { AppConfig } from '../app.config';


export class AppAuthConfig {
  static get authConfig(): AuthConfig {
    return {
      // Url of the Identity Provider
      issuer: AppConfig.settings.aadb2c.issuer,

      // URL of the SPA to redirect the user to after login
      redirectUri: window.location.origin + '/signin-oidc',

      postLogoutRedirectUri: window.location.origin + '/home',

      // URL of the SPA to redirect the user after silent refresh
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

      // The SPA's id. The SPA is registerd with this id at the auth-server
      clientId: AppConfig.settings.aadb2c.clientId,

      // set the scope for the permissions the client should request
      scope: `openid email ${AppConfig.settings.aadb2c.apiScope}`,

      // silentRefreshShowIFrame: true,

      showDebugInformation: true,

      sessionChecksEnabled: false,
      strictDiscoveryDocumentValidation: false,
      skipIssuerCheck: true,
      oidc: true,
      clearHashAfterLogin: false
    };
  }
}

// export const authConfig: AuthConfig = {
//  // Url of the Identity Provider
//  issuer: AppConfig.settings.aadb2c.issuer,

//  // URL of the SPA to redirect the user to after login
//  redirectUri: window.location.origin + '/signin-oidc',

//  postLogoutRedirectUri: window.location.origin + '/home',

//  // URL of the SPA to redirect the user after silent refresh
//  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

//  // The SPA's id. The SPA is registerd with this id at the auth-server
//  clientId: AppConfig.settings.aadb2c.clientId,

//  // set the scope for the permissions the client should request
//  scope: `openid email ${AppConfig.settings.aadb2c.apiScope}`,

//  // silentRefreshShowIFrame: true,

//  showDebugInformation: true,

//  sessionChecksEnabled: false,
//  strictDiscoveryDocumentValidation: false,
//  skipIssuerCheck: true,
//  oidc: true,
//  clearHashAfterLogin : false
// };
