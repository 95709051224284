import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { filter, tap, switchMap } from 'rxjs/operators';
import { OAuthService, OAuthEvent, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { UserService } from '../services/user.service';
import { IGavilonUser } from '../../models/user';
import pageSettings from '../../config/page-settings';
import { AppAuthConfig } from '../auth.config';
import { AppConfig } from '../../app.config';

declare let _hsq: any;

@Component({
    selector: 'app-signin-oidc',
    templateUrl: './signin-oidc.component.html',
    styles: []
})
export class SigninOidcComponent implements OnInit, OnDestroy {

    sub: Subscription;
    subError: Subscription;
    // get requestAccessToken() {
    //  return this.oauthService.requestAccessToken;
    // }

    // get id_token() {
    //  return this.oauthService.getIdToken();
    // }

    // get access_token() {
    //  return this.oauthService.getAccessToken();
    // }

    // get id_token_expiration() {
    //  return this.oauthService.getIdTokenExpiration();
    // }

    // get access_token_expiration() {
    //  return this.oauthService.getAccessTokenExpiration();
    // }
    pageSettings = pageSettings;

    constructor(private router: Router, private oauthService: OAuthService, private userService: UserService) {
        this.sub = this.oauthService.events
            .pipe(
              switchMap((e: OAuthEvent) => {
                if (e.type === 'invalid_nonce_in_state') {
                  this.oauthService.logOut(true);
                  return this.router.navigate(['/unauthorized']);
                }
                if (e.type === 'token_received') {
                  if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
                    localStorage.removeItem('currentUser');
                    return userService.getCurrentUser();
                  }
                }
                return of(null);
                })
            )
          .subscribe((currentUser: IGavilonUser | null) => {
              if (currentUser) {
                    if (currentUser.cookiesConsentDate) {
                        if (!currentUser.cookiesConsent) {
                            _hsq.push(['doNotTrack']);
                        }
                    }

                    let returnUrl = '/myaccount';

                    if (!currentUser.cookiesConsentDate) {
                        this.pageSettings.pageCookiePolicy = true;
                    }

                    if (!(currentUser.isGrainUser || currentUser.isGrainPlusUser || currentUser.isIngredientUser || currentUser.isTransporationUser)) {
                        returnUrl = '/myaccount/start';
                    } else {
                        if (currentUser.isTransporationUser && !(currentUser.isGrainUser || currentUser.isGrainPlusUser || currentUser.isIngredientUser)) {
                            returnUrl = '/trucking/scale-tickets';
                        } else {
                            try {
                                const localStorageUrl = localStorage.getItem('returnUrl');
                                if (localStorageUrl) {
                                    returnUrl = localStorageUrl;
                                    localStorage.removeItem('returnUrl');
                                }
                            } catch (e) {
                                console.error(e);
                            }
                        }
                    }

                    if (currentUser.statusId == 9) {
                        returnUrl = '/unauthorized';
                        this.oauthService.logOut(true);
                    }

                    return this.router.navigate([returnUrl]);
                }
            });
    }

    ngOnInit() {

        this.subError = this.oauthService.events
            .pipe(filter(e => e.type === 'token_error'))
            .subscribe(e => {
                if (e instanceof OAuthErrorEvent) {
                    if (((e.params as any).error_description).toString().startsWith('AADB2C90118')) {
                        // AADB2C90118 is the specific B2C error when "Forgot password" link is clicked.
                        this.oauthService.configure({ ...AppAuthConfig.authConfig, issuer: AppConfig.settings.aadb2c.passwordReset });
                        this.oauthService.loadDiscoveryDocument(`${AppConfig.settings.aadb2c.passwordReset}/.well-known/openid-configuration`).then(() => {
                            this.oauthService.initImplicitFlow();
                        });
                    }
                    if (((e.params as any).error_description).toString().startsWith('AADB2C90091')) {
                        // AADB2C90091 is the specific B2C error when User clicks on Cancel.
                        this.router.navigate(['/home']);
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
        this.subError.unsubscribe();
    }

}
