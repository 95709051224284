import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styles: []
})
export class CookiePolicyComponent implements OnInit {

  @Output() acceptCookiePolicy = new EventEmitter<boolean>();

  loading = false;

  constructor() { }

  ngOnInit() {
  }

  onCookiePolicy(accept: boolean) {
    this.loading = true;
    this.acceptCookiePolicy.emit(accept);
  }
}
