import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { IAppConfig } from './models/app-config.model';

import currentConfig from './../assets/config.json';

@Injectable()
export class AppConfig {

  static settings: IAppConfig;

  constructor(private http: HttpClient) { }

  // load(): Promise<IAppConfig> {
  //  const jsonFile = `assets/config.json`;
  //  return this.http.get<IAppConfig>(jsonFile)
  //    .tap((response: IAppConfig) => {
  //      AppConfig.settings = <IAppConfig>response;
  //    })
  //    .toPromise();
  // }

  load() {
    const jsonFile = `/assets/config.json`;

    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile, { headers })
        .toPromise().then((response: IAppConfig) => {
          // Force a reload of the page if the Azure AD B2C settings have changed
          if (response.aadb2c.issuer !== currentConfig.aadb2c.issuer) {
            location.reload();
          }
          
          AppConfig.settings = (response as IAppConfig);
          console.log('Current Config: ', JSON.stringify(AppConfig.settings))
          resolve();
        }).catch((response: any) => {
            reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
  }
}
