import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { UserService } from './services/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authStorage: OAuthStorage, private userService: UserService, private _router: Router) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/api') && !req.url.startsWith('/api/producersedge')) {
      const accessToken = this.authStorage.getItem('access_token');
      const headers = req.headers.set('Authorization', `Bearer ${accessToken}`);
      // let gavilonUserId: number | null = null;
      // if (this.userService.currentUser) {
      //  gavilonUserId = this.userService.currentUser.id;
      //  headers.set('Gavilon-Id', `${gavilonUserId}`);
      // }
      const authReq = req.clone({ headers });
      try {
        return next.handle(authReq);
      } catch(error) {
          console.error('Unauthorized error: ', error);
          const respError = error as HttpErrorResponse;
          if (respError && (respError.status === 401 || respError.status === 403)) {
            this._router.navigate(['/unauthorized']);
          }
      }
    } else {
      return next.handle(req);
    }
  }
}
