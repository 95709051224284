import { Component, Input, Output, EventEmitter, Renderer2, OnDestroy } from '@angular/core';
import pageSettings from '../../config/page-settings';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from '../../user/services/user.service';
import { IGavilonUser, IUpdateUserCookieConsent } from '../../models/user';
import { RouterModule, Router } from '@angular/router';
import { AccountListComponent } from '../account-list/account-list.component'

@Component({
    selector: 'header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnDestroy {
    @Input() pageSidebarTwo;
    @Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
    @Output() toggleMobileSidebar = new EventEmitter<boolean>();
    @Output() toggleMobileRightSidebar = new EventEmitter<boolean>();
    pageSettings = pageSettings;
    userName = '';

    mobileSidebarToggle() {
        this.toggleMobileSidebar.emit(true);
    }
    mobileRightSidebarToggle() {
        this.toggleMobileRightSidebar.emit(true);
    }
    toggleSidebarRight() {
        this.toggleSidebarRightCollapsed.emit(true);
    }

    mobileTopMenuToggle() {
        this.pageSettings.pageMobileTopMenuToggled = !this.pageSettings.pageMobileTopMenuToggled;
    }

    mobileMegaMenuToggle() {
        this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings.pageMobileMegaMenuToggled;
    }

    ngOnDestroy() {
        this.pageSettings.pageMobileTopMenuToggled = false;
        this.pageSettings.pageMobileMegaMenuToggled = false;
    }

    constructor(private renderer: Renderer2,
                private oauthService: OAuthService,
                private userService: UserService,
                private router: Router) {

    }

    editProfile() {
        return this.router.navigate(['/myaccount/profile']);
    }

    logout() {
        localStorage.removeItem('currentUser');
        this.oauthService.logOut();
    }

    ngOnInit() {
        this.userService.getCurrentUser().subscribe(
            (currentUser: IGavilonUser | null) => {
                if (currentUser) {
                    this.userName = `${currentUser.firstName} ${currentUser.lastName}`;
                    if (!currentUser.cookiesConsentDate) {
                        this.pageSettings.pageCookiePolicy = true;
                    }
                }
            }
        );
    }

    onAcceptCookiePolicy(cookieConsent: boolean): void {

        const userInfoToUpdate: IUpdateUserCookieConsent = {
            cookiesConsent: cookieConsent
        };

        this.userService.updateUserCookieConsent(userInfoToUpdate).subscribe(
            result => {
                // this.modalService.dismissAll();
                // this.postError = false;
                // this.postMessage = 'Profile updated';
                // this.modalService.open(this.actionResultModal, { centered: true, size: 'sm' });
                // this.currentUser = { ...result };
                this.pageSettings.pageCookiePolicy = false;
            },
            (error: any) => {
                //  this.modalService.dismissAll();
                //  this.postError = true;
                //  this.postMessage = <any>error;
                //  this.modalService.open(this.actionResultModal, { centered: true, size: 'sm' });
                this.pageSettings.pageCookiePolicy = false;
            },
            () => { this.pageSettings.pageCookiePolicy = false; }
        );
  }

  dismissViterraBanner(): void {
    document.getElementById('viterra-banner').style.display = 'none';

  }

}
