const pageSettings = {
  pageSidebarMinified: false,
  pageContentFullHeight: false,
  pageContentFullWidth: false,
  pageContentInverseMode: false,
  pageWithFooter: true,
  pageWithoutSidebar: false,
  pageSidebarRight: false,
  pageSidebarRightCollapsed: false,
  pageSidebarTwo: false,
  pageSidebarWide: true,
  pageSidebarTransparent: false,
  pageSidebarLight: true,
  pageTopMenu: false,
  pageEmpty: false,
  pageBodyWhite: false,
  pageMegaMenu: true,
  pageLanguageBar: false,
  pageMobileSidebarToggled: false,
  pageMobileSidebarFirstClicked: false,
  pageMobileSidebarRightToggled: false,
  pageMobileSidebarRightFirstClicked: false,
  pageMobileTopMenuToggled: false,
  pageMobileMegaMenuToggled: false,
  pageCookiePolicy: false,
};


export default pageSettings;
