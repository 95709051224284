import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';

import { SigninOidcComponent } from './pages/signin-oidc.component';
import { UnauthorizedComponent } from './pages/unauthorized.component';

const userRoutes: Routes = [
  { path: 'signin-oidc', component: SigninOidcComponent },
  { path: 'unauthorized', component: UnauthorizedComponent }
];


@NgModule({
  declarations: [SigninOidcComponent, UnauthorizedComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(userRoutes)
  ]
})
export class UserModule { }
