import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IGrainBalanceGroupLocation } from '../../models/grainBalances';
import { IContractGroupCommodity, IContract, IContractFilter, ISelectedContractFilter } from '../../models/contracts';
import { IRecentTransaction } from '../../models/recentTransactions';
import { ITicket, ITicketFilter, ISelectedTicketFilter } from '../../models/tickets';
import { ISettlement, ISettlementGroupCommodity, ISettlementsFilter, ISettlementTicket, ISelectedSettlementFilter } from '../../models/settlements';
import { State } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private accountsUrl = '/api/myaccount/accounts';

  contractFilter: IContractFilter[] = [];
  initialContractFilter: ISelectedContractFilter = { contractStatus: 'A', commodity: '', location: '' };
  currentContractFilter: ISelectedContractFilter;

  currentSettlementFilter: ISelectedSettlementFilter;

  currentTicketFilter: ISelectedTicketFilter;
  currentTicketGridState: State;

  constructor(private http: HttpClient) {
    this.resetAllFilters();
  }

  resetAllFilters(): void {
    this.currentContractFilter = { ...this.initialContractFilter };

    const lastMonthDate = new Date();
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

    this.currentSettlementFilter = {
      commodity: '',
      startDate: lastMonthDate,
      endDate: new Date(),
      location: ''
    };

    this.currentTicketFilter = {
      commodity: '',
      startDate: lastMonthDate,
      endDate: new Date(),
      location: ''
    };

    this.currentTicketGridState = {
      skip: 0,
      take: 20,

      sort: [{
        field: 'ticketNumber',
        dir: 'desc'
      }]
    };
  }

  getRecentTransactions(accountId: string): Observable<IRecentTransaction[]> {
    const url = `${this.accountsUrl}/${accountId}/recentActivity`;
    return this.http.get<IRecentTransaction[]>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  getRecentContracts(accountId: string): Observable<IContractGroupCommodity[]> {
    const url = `${this.accountsUrl}/${accountId}/recentContracts`;
    return this.http.get<IContractGroupCommodity[]>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  getAccountBalances(accountId: string): Observable<IGrainBalanceGroupLocation[]> {
    const url = `${this.accountsUrl}/${accountId}/balances`;
    return this.http.get<IGrainBalanceGroupLocation[]>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  getAccountBalancesDownload(accountId: string) {
    const url = `${this.accountsUrl}/${accountId}/balancesExport`;
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAccountContracts(accountId: string, contractStatus: string, commodity: string, location: string): Observable<IContractGroupCommodity[]> {
    const url = `${this.accountsUrl}/${accountId}/contracts?contractStatus=${contractStatus}&commodity=${commodity}&contractLocation=${location}`;
    return this.http.get<IContractGroupCommodity[]>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  getAccountContractsDownload(accountId: string, contractStatus: string, commodity: string, location: string) {
    const url = `${this.accountsUrl}/${accountId}/contractsExport?contractStatus=${contractStatus}&commodity=${commodity}&contractLocation=${location}`;
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAccountContract(accountId: string, contractId: string): Observable<IContract> {
    const url = `${this.accountsUrl}/${accountId}/contracts/${contractId}`;
    return this.http.get<IContract>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  getAccountContractFilters(accountId: string): Observable<IContractFilter[]> {
    // if (this.contractFilter) {
    //  return of(this.contractFilter);
    // }
    const url = `${this.accountsUrl}/${accountId}/contractfilters`;
    return this.http.get<IContractFilter[]>(url)
      .pipe(
        tap(data => console.table(data)),
        tap(data => this.contractFilter = data),
        catchError(this.handleError)
      );
  }

  setAccountContractCurrentFilter(contractStatus: string, commodity: string, location: string): void {
    this.currentContractFilter = { contractStatus, commodity, location };
  }

  getAccountTickets(accountId: string, startDate: Date, endDate: Date, location: string, commodity: string): Observable<ITicket[]> {
    const url = `${this.accountsUrl}/${accountId}/tickets?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&location=${location}&commodity=${commodity}`;
    return this.http.get<ITicket[]>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  getAccountTicketsDownload(accountId: string, startDate: Date, endDate: Date, location: string, commodity: string) {
    const url = `${this.accountsUrl}/${accountId}/ticketsExport?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&location=${location}&commodity=${commodity}`;
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAccountTicketFilters(accountId: string): Observable<ITicketFilter[]> {
    const url = `${this.accountsUrl}/${accountId}/ticketfilters`;
    return this.http.get<ITicketFilter[]>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  setAccountTicketCurrentFilter(startDate: Date, endDate: Date, commodity: string, location: string): void {
    this.currentTicketFilter = { startDate, endDate, commodity, location };
  }

  setAccountTicketCurrentGridState(gridState: State): void {
    this.currentTicketGridState = { ...gridState };
  }

  getAccountTicket(accountId: string, ticketId: string): Observable<ITicket> {
    const url = `${this.accountsUrl}/${accountId}/tickets/${ticketId}`;
    return this.http.get<ITicket>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  getAccountSettlements(accountId: string, startDate: Date, endDate: Date, commodity: string, location: string): Observable<ISettlementGroupCommodity[]> {
    const url = `${this.accountsUrl}/${accountId}/settlements?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&commodity=${commodity}&location=${location}`;
    return this.http.get<ISettlementGroupCommodity[]>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  getAccountSettlementsDownload(accountId: string, startDate: Date, endDate: Date, commodity: string, location: string) {
    const url = `${this.accountsUrl}/${accountId}/settlementsExport?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&commodity=${commodity}&location=${location}`;
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  getAccountSettlementFilters(accountId: string): Observable<ISettlementsFilter[]> {
    const url = `${this.accountsUrl}/${accountId}/settlementfilters`;
    return this.http.get<ISettlementsFilter[]>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  setAccountSettlementCurrentFilter(startDate: Date, endDate: Date, commodity: string, location: string): void {
    this.currentSettlementFilter = { startDate, endDate, commodity, location };
  }

  getAccountSettlement(accountId: string, settlementId: string): Observable<ISettlement> {
    const url = `${this.accountsUrl}/${accountId}/settlements/${settlementId}`;
    return this.http.get<ISettlement>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  getAccountSettlementTickets(accountId: string, settlementContractId: string): Observable<ISettlementTicket[]> {
    const url = `${this.accountsUrl}/${accountId}/settlementTickets/${settlementContractId}`;
    return this.http.get<ISettlementTicket[]>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}, body was: ${err.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }
}
