<!-- begin #sidebar -->
<div id="sidebar" class="sidebar sidebar-grid" [ngClass]="{ 'sidebar-transparent': this.pageSidebarTransparent}">
  <!-- begin sidebar scrollbar -->
  <div class="overflow-scroll height-full" #sidebarScrollbar *ngIf="desktopMode" (scroll)="onScroll($event)">
    <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
  </div>
  <div class="mobile-scroll" *ngIf="mobileMode" (scroll)="onScroll($event)">
    <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
  </div>
  <!-- end sidebar scrollbar -->
  <ng-template #sidebarNav>
    <!-- begin sidebar nav -->
    <ul class="nav">
      <li class="nav-header">Navigation</li>

      <!-- render menu item -->
      <ng-template #sidebarMenu let-menu="menu" let-active="active">
        <span class="badge pull-right" *ngIf="menu.badge">{{ menu.badge }}</span>
        <b class="caret" *ngIf="menu.caret"></b>
        <div class="icon-img" *ngIf="menu.img"><img *ngIf="active" src="{{ menu.imgActive }}" /><img *ngIf="!active" src="{{ menu.img }}" /></div>
        <i class="{{ menu.icon }}" *ngIf="menu.icon"></i>
        <span *ngIf="menu.title">
          {{ menu.title }}
          <span class="label label-theme m-l-5" *ngIf="menu.label">{{ menu.label }} </span>
        </span>
      </ng-template>

      <!-- render submenu item -->
      <ng-template #sidebarSubMenu let-menu="menu">
        <b class="caret pull-right" *ngIf="menu.caret"></b>
        {{ menu.title }}
        <i class="fa fa-paper-plane text-theme m-l-5" *ngIf="menu.highlight"></i>
      </ng-template>

      <!-- sidebar menu -->
      <ng-container *ngFor="let menu of menus">
        <li routerLinkActive="active" #rla1="routerLinkActive" [routerLinkActiveOptions]="{exact: true}" [ngClass]="{ 'expand': (menu.state == 'expand') }"
            [class.d-none]="(menu.audience?.indexOf('grainPlus') > -1 && !currentUser?.isGrainPlusUser) ||
                            (menu.audience?.indexOf('grains') > -1 && !currentUser?.isGrainUser) ||
                            (menu.audience?.indexOf('ingredients') > -1 && !currentUser?.isIngredientUser) ||
                            (menu.audience?.indexOf('trucking') > -1 && !currentUser?.isTransporationUser) ||
                            (menu.audience?.indexOf('spq') > -1 && (currentUser?.statusId !== 5 && currentUser?.statusId !== 7))
                            "
        >
          <a *ngIf="!menu.url" (click)="(menu.submenu) ? expandCollapseSubmenu(menu, menus, rla1) : ''">
            <ng-container *ngTemplateOutlet="sidebarMenu; context: {menu: menu, active: rla1.isActive}"></ng-container>
          </a>
          <a *ngIf="menu.url" [routerLink]="menu.url">
            <ng-container *ngTemplateOutlet="sidebarMenu; context: {menu: menu, active: rla1.isActive}"></ng-container>
          </a>

          <!-- sidebar submenu lvl-1 -->
          <div [@expandCollapse]="(menu.state) ? menu.state : ((rla1.isActive && !menu.state) ? 'active' : 'collapse')" *ngIf="menu.submenu">
            <ul class="sub-menu" [ngStyle]="{'margin-top.px': (this.pageSettings.pageSidebarMinified) ? - (scrollTop) : 0 }">
              <ng-container *ngFor="let sub1 of menu.submenu">
                <li routerLinkActive="active" #rla2="routerLinkActive" [routerLinkActiveOptions]="{exact: true}" [ngClass]="{ 'expand': (sub1.state == 'expand') }">
                  <a *ngIf="!sub1.url" (click)="(sub1.submenu) ? expandCollapseSubmenu(sub1, menu, rla2) : ''">
                    <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub1}"></ng-container>
                  </a>
                  <a *ngIf="sub1.url" [routerLink]="sub1.url">
                    <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub1}"></ng-container>
                  </a>

                  <!-- sidebar submenu lvl-2 -->
                  <div [@expandCollapse]="(sub1.state) ? sub1.state : ((rla2.isActive && !sub1.state) ? 'active' : 'collapse')" *ngIf="sub1.submenu">
                    <ul class="sub-menu">
                      <ng-container *ngFor="let sub2 of sub1.submenu">
                        <li routerLinkActive="active" #rla3="routerLinkActive" [routerLinkActiveOptions]="{exact: true}" [ngClass]="{ 'expand': (sub2.state == 'expand') }">
                          <a *ngIf="!sub2.url" (click)="(sub2.submenu) ? expandCollapseSubmenu(sub2, sub1.submenu, rla3) : ''">
                            <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub2}"></ng-container>
                          </a>
                          <a *ngIf="sub2.url" [routerLink]="sub2.url" (click)="(sub2.submenu) ? expandCollapseSubmenu(sub2, sub1.submenu, rla3) : ''">
                            <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub2}"></ng-container>
                          </a>

                          <!-- sidebar submenu lvl-3 -->
                          <div [@expandCollapse]="(sub2.state) ? sub2.state : ((rla3.isActive && !sub2.state) ? 'active' : 'collapse')" *ngIf="sub2.submenu">
                            <ul class="sub-menu">
                              <ng-container *ngFor="let sub3 of sub2.submenu">
                                <li routerLinkActive="active" #rla4="routerLinkActive" [routerLinkActiveOptions]="{exact: true}" [ngClass]="{ 'expand': (sub3.state == 'expand') }">
                                  <a *ngIf="!sub3.url" (click)="(sub3.submenu) ? expandCollapseSubmenu(sub3, sub2.submenu, rla4) : ''">
                                    <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub3}"></ng-container>
                                  </a>
                                  <a *ngIf="sub3.url" [routerLink]="sub3.url">
                                    <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub3}"></ng-container>
                                  </a>
                                </li>
                              </ng-container>
                            </ul>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </li>
      </ng-container>
    </ul>
    <!-- end sidebar nav -->
  </ng-template>
</div>
<div class="sidebar-bg"></div>
<!-- end #sidebar -->
