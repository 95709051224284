import { Component, HostListener, Renderer2, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, NavigationCancel, NavigationStart, NavigationError, ActivatedRoute, Event, ActivationEnd } from '@angular/router';
import pageSettings from '../../config/page-settings';
import * as global from '../../config/globals';
import * as PullToRefresh from 'pulltorefreshjs';
import {ProgressBarMode } from '@angular/material/progress-bar';
import {MatSliderModule} from '@angular/material/slider';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styles: []
})
export class ShellComponent implements OnInit {
    progressValue: number = 50;
    progressTimer;
    barMode = "determinate";
    loadingPage: boolean = false;
  
    // This will be used to force stop (if an error occurs, or the user stops loading)
    stopProgress = false;

    constructor(private titleService: Title, private router: Router, private renderer: Renderer2) {
        this.loadingPage = true;
        router.events.subscribe((e) => {
            this.navigationObserver(e);
        });
    }

    private navigationObserver(event: Event): void {
        if (event instanceof NavigationStart) {
            if (window.innerWidth < 768) {
                this.pageSettings.pageMobileSidebarToggled = false;
            }
        }

        if (this.loadingPage) {
          // Increase 1% every 25 milliseconds, adjust it to your preference
            this.progressTimer = setInterval(() => {
                this.loading();
            }, 25);
        }
    
        if (event instanceof NavigationEnd) {
          // When the navigation finishes, fill the bar completely

          this.progressValue = 100;
        }
    
        /* 
        * If the navigation is canceled or an error occurs, 
        * stop the progress bar and change its color.  
        */
    
        if (event instanceof NavigationCancel) {
          this.stopProgress = true;
        }
    
        if (event instanceof NavigationError) {
          this.stopProgress = true;
        }
    
      }
    
      // Function to increase the value of the progress bar    
      private loading(): void {
        /*
        * Leave 5% in case an unusual delay occurs, in the previous
        * function it is filled to 100% if the load ends successfully
        */
        if (this.progressValue >= 95 || this.stopProgress) {
          clearInterval(this.progressTimer);
        } else {
          this.progressValue++;
        }
      }

    pageSettings;

    // window scroll
    pageHasScroll;

    ngOnInit() {
        // page settings
        this.pageSettings = pageSettings;

        PullToRefresh.destroyAll();

        PullToRefresh.init({
            mainElement: '#content',
            triggerElement: '#content'
        }); 
    }
    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {
        let doc = document.documentElement;
        let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 0) {
            this.pageHasScroll = true;
        } else {
            this.pageHasScroll = false;
        }
    }

    // set page minified
    onToggleSidebarMinified(val: boolean): void {
        if (this.pageSettings.pageSidebarMinified) {
            this.pageSettings.pageSidebarMinified = false;
        } else {
            this.pageSettings.pageSidebarMinified = true;
        }
    }

    // set page right collapse
    onToggleSidebarRight(val: boolean): void {
        if (this.pageSettings.pageSidebarRightCollapsed) {
            this.pageSettings.pageSidebarRightCollapsed = false;
        } else {
            this.pageSettings.pageSidebarRightCollapsed = true;
        }
    }

    // hide mobile sidebar
    onHideMobileSidebar(val: boolean): void {
        if (this.pageSettings.pageMobileSidebarToggled) {
            if (this.pageSettings.pageMobileSidebarFirstClicked) {
                this.pageSettings.pageMobileSidebarFirstClicked = false;
            } else {
                this.pageSettings.pageMobileSidebarToggled = false;
            }
        }
    }

    // toggle mobile sidebar
    onToggleMobileSidebar(val: boolean): void {
        if (this.pageSettings.pageMobileSidebarToggled) {
            this.pageSettings.pageMobileSidebarToggled = false;
        } else {
            this.pageSettings.pageMobileSidebarToggled = true;
            this.pageSettings.pageMobileSidebarFirstClicked = true;
        }
    }


    // hide right mobile sidebar
    onHideMobileRightSidebar(val: boolean): void {
        if (this.pageSettings.pageMobileRightSidebarToggled) {
            if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
                this.pageSettings.pageMobileRightSidebarFirstClicked = false;
            } else {
                this.pageSettings.pageMobileRightSidebarToggled = false;
            }
        }
    }

    // toggle right mobile sidebar
    onToggleMobileRightSidebar(val: boolean): void {
        if (this.pageSettings.pageMobileRightSidebarToggled) {
            this.pageSettings.pageMobileRightSidebarToggled = false;
        } else {
            this.pageSettings.pageMobileRightSidebarToggled = true;
            this.pageSettings.pageMobileRightSidebarFirstClicked = true;
        }
    }

    ngAfterViewInit() {
        this.loadingPage = false;
      }
    
}
