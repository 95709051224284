import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'home',
  templateUrl: './home.html'
})

export class HomePage {
  deviceInfo = null;
  isBrowserIE = false;

  constructor(private router: Router, private oauthService: OAuthService, private deviceService: DeviceDetectorService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isBrowserIE = this.deviceService.browser == 'IE';

    // if (this.oauthService.hasValidAccessToken()) {
    //  this.router.navigate(["/myaccount"])
    // } else {
    //  this.oauthService.silentRefresh().catch(() => console.debug('silent refresh error'));
    // }
  }

  login() {
    this.oauthService.initImplicitFlow();
    // the parameter here is optional. It's passed around and can be used after logging in
  }
}
