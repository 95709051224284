import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, delay } from 'rxjs/operators';
import { of, race, Subscription } from 'rxjs';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { AppAuthConfig } from './user/auth.config';
import { UserService } from './user/services/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';


// declare ga and _hsq as a function to set and sent the events
declare let ga: any;
declare let _hsq: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {

  private routeSub: Subscription;

  constructor(private router: Router,
              private oauthService: OAuthService,
              private userService: UserService,
              private titleService: Title,
              private route: ActivatedRoute,
              private deviceService: DeviceDetectorService) {

    this.configureWithNewConfigApi();

    this.routeSub = router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        // var title = 'myGavilon | ' + this.route.snapshot.firstChild.data['title'];
        let title = 'myViterra';
        const routeSnapshot = this.route.snapshot;
        if (this.route.snapshot.firstChild) {
          if (this.route.snapshot.firstChild.firstChild) {
            const dataTitle = this.route.snapshot.firstChild.firstChild.data.title;
            if (dataTitle) {
              title = title + ' | ' + dataTitle;
            }
          }
        }

        this.titleService.setTitle(title);

        // Traffic Analytics
        if (e.urlAfterRedirects) {
          if (e.urlAfterRedirects.indexOf('signin-oidc') == -1) {
            ga('set', 'page', e.urlAfterRedirects);
            ga('send', 'pageview');

            _hsq.push(['setPath', e.urlAfterRedirects]);
            _hsq.push(['trackPageView']);
          }
        }

      });
  }

  ngOnInit(): void {
    console.log('app component started');
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  private configureWithNewConfigApi() {
    this.oauthService.requestAccessToken = true;
    this.oauthService.configure(AppAuthConfig.authConfig);

    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.oauthService.loadDiscoveryDocument()
      .then(() => this.oauthService.tryLogin())
      .then(() => {
        if (!this.oauthService.hasValidAccessToken()) {
          this.oauthService.silentRefresh().catch(() => console.debug('silent refresh error'));
        }
      });

    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events.subscribe(e => {
      // tslint:disable-next-line:no-console
      console.debug('oauth/oidc event', e);
    });

    // this.oauthService.events
    //  .pipe(filter(e => e.type === 'silently_refreshed'))
    //  .subscribe(e => {
    //    this.router.navigate(['/myaccount']);
    //  });

    // this.oauthService.events
    //  .pipe(filter(e => e.type === 'token_received'))
    //  .subscribe(e => {
    //    this.router.navigate(['/myaccount']);
    //  });
  }
}
