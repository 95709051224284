<div id="cookie-banner" class="bg-green text-white text-center">
  <div class="cookie-banner--inner">
    <div class="row">
      <div class="col">
        <div class="cookie-banner--text text-left">
          <span>
            To give you the best possible experience, this site uses cookies.  If you continue browsing, you accept our use of cookies. You can review our <a href="https://viterra.us/cookies-policy" class="cookie-banner--link text-white" target="_blank">Cookies Policy</a> and <a href="https://viterra.us/terms-of-use" class="cookie-banner--link text-white" target="_blank">Terms of Use</a>.  We’ve updated our privacy policy effective January 1, 2020.  Please visit <a href="https://viterra.us/privacy-policy" class="cookie-banner--link text-white" target="_blank">https://viterra.us/privacy-policy</a> to review our privacy practices.
          </span>
        </div>
      </div>
      <div class="col-lg-auto">
        <div class="cookie-banner--button-group">
          <button class="cookie-banner--button-accept btn btn-outline-white no-box-shadow" (click)="onCookiePolicy(true)">Accept</button> &nbsp;
          <button class="cookie-banner--button-decline btn btn-outline-white no-box-shadow" (click)="onCookiePolicy(false)">Decline</button>
          <i class="fas fa-spinner fa-2x fa-spin m-l-10" [class.invisible]="!loading"></i>
        </div>
      </div>
    </div>
  </div>
</div>
