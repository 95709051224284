import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { catchError, tap, flatMap, exhaustMap } from 'rxjs/operators';
import { of, Observable, throwError, BehaviorSubject } from 'rxjs';

import { IUserAccount, IAddUserAccount } from '../../models/userAccount';
import { UserService } from '../../user/services/user.service';
import { IGavilonUser } from '../../models/user';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccountService {

  private userAccountsUrl = '/api/myaccount/useraccounts';
  private userAccounts: IUserAccount[];

  private currentUserAccountSource = new BehaviorSubject<IUserAccount | null>(null);
  currentUserAccountChanges$ = this.currentUserAccountSource.asObservable();

  constructor(private http: HttpClient, private userService: UserService, private accountService: AccountService) { }

  changeCurrentAccount(selectedAccount: IUserAccount | null): void {
    this.accountService.resetAllFilters();
    this.currentUserAccountSource.next(selectedAccount);
  }

  getUserAccounts(): Observable<IUserAccount[]> {
    if (this.userAccounts) {
      return of(this.userAccounts);
    }
    return this.userService.getCurrentUser().pipe(
      exhaustMap((gavUser: IGavilonUser) => {
        return this.http.get<IUserAccount[]>(this.userAccountsUrl).pipe(
          tap(data => console.table(data)),
          tap(data => {
            this.userAccounts = data;
            if (data.length > 0) {
              this.changeCurrentAccount(data[0]);
            }
          }),
          catchError(this.handleError)
        );
      })
    );
  }

  getUserAccount(accountNumber: string): Observable<IUserAccount> {
    if (this.userAccounts) {
      const foundItem = this.userAccounts.find(item => item.sourceAccountId === accountNumber);
      if (foundItem) {
        return of(foundItem);
      }
    }
    const url = `${this.userAccountsUrl}/${accountNumber}`;
    return this.http.get<IUserAccount>(url)
      .pipe(
        tap(data => console.table(data)),
        catchError(this.handleError)
      );
  }

  addUserAccount(addUserAccount: IAddUserAccount): Observable<IUserAccount> {
    return this.http.post<IUserAccount>(this.userAccountsUrl, addUserAccount)
      .pipe(
        tap(data => this.userAccounts.push(data)),
        catchError(this.handleError)
      );
  }

  deleteUserAccount(userAccountId: number): Observable<void> {
    const url = `${this.userAccountsUrl}/${userAccountId}`;
    return this.userService.getCurrentUser().pipe(
      flatMap((gavUser: IGavilonUser) => {
        return this.http.delete<void>(url).pipe(
          tap(data => console.log(data)),
          tap(data => {
            const foundIndex = this.userAccounts.findIndex(item => item.id === userAccountId);
            if (foundIndex > -1) {
              this.userAccounts.splice(foundIndex, 1);
            }
          }),
          catchError(this.handleError)
        );
      })
    );
  }


  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // errorMessage = `Backend returned code ${err.status}, error message was: ${err.error}`;
      errorMessage = `${err.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }
}
