import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserAccountService } from '../../myaccount/services/user-account.service';
import { IUserAccount } from '../../models/userAccount';


@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html'
})
export class AccountListComponent implements OnInit, OnDestroy {

  currentAccount: IUserAccount | null;
  userAccounts: IUserAccount[];
  errorMessage: string;
  sub: Subscription;

  // get selectedAccount(): IUserAccount {
  //  return this.selectedAccount;
  // }

  // set selectedAccount(newAccount: IUserAccount) {
  //  this.userAccountService.changeCurrentAccount(newAccount);
  // }

  constructor(private userAccountService: UserAccountService, private router: Router) { }

  ngOnInit() {
    this.sub = this.userAccountService.currentUserAccountChanges$.subscribe(
      selectedAccount => this.currentAccount = selectedAccount
    );

    this.userAccountService.getUserAccounts().subscribe(
      (userAccounts: IUserAccount[]) => {
        this.userAccounts = userAccounts;
      },
      (error: any) => this.errorMessage = (error as any)
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public valueChange(newAccount: IUserAccount): void {
    this.userAccountService.changeCurrentAccount(newAccount);

    if (newAccount.nameIdType === 'T') {
      this.router.navigate(['/trucking/scale-tickets']);
    }
  }
}
