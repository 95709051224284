<!-- begin #header -->

<div id="header" class="header navbar-inverse">

  <!-- begin navbar-header -->
  <div class="navbar-header">
    <a href="#" class="navbar-brand" style="padding: 0 !important;"><img src="/images/Viterra_Logo_Green_RGB.svg" alt="Viterra"></a>
  </div>
  <!-- end navbar-header -->
  <!-- begin header-nav -->
  <ul class="navbar-nav navbar-right">

    <li class="dropdown navbar-user" ngbDropdown>
      <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
        <div class="image image-icon bg-white text-grey-darker">
          <i class="fa fa-user"></i>
        </div>
        <span class="d-none d-md-inline">{{userName}}</span> <b class="caret"></b>
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <button (click)="logout()" class="dropdown-item">Log Out</button>
      </div>
    </li>
  </ul>
  <!-- end header navigation right -->
</div>
<!-- end #header -->

<div class="panel">
  <div class="panel body">
    <div class="p-t-40 p-b-40 m-t-40 m-b-40 text-center">
      <div class="p-b-40">
        <div *ngIf="this.user" class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
          <p *ngIf="this.user.statusId === 9">
            Your user account is not active, please contact your local Viterra office for questions or further assistance:<br />
            <a href="https://viterra.us/what-we-do/grower-services/US-locations">https://viterra.us/what-we-do/grower-services/US-locations</a>
          </p>
          <p *ngIf="this.user.statusId !== 9">
            We are performing maintenance on the site. Please <a class="btn btn-outline-viterra-green" (click)="logout()">click here</a> to log out and re-login.
            <br />
            Automatically logging you out and redirecting to home page in {{secondsRemaining}}...
          </p>
        </div>
        <div *ngIf="!this.user" class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <p>
          Your user account is not active, please contact your local Viterra office for questions or further assistance:<br />
          <a href="https://viterra.us/what-we-do/grower-services/US-locations">https://viterra.us/what-we-do/grower-services/US-locations</a>
        </p>
      </div>
      </div>
    </div>
  </div>
</div>
