import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

// Home
import { HomePage } from './pages/home/home';
import { ShellComponent } from './components/shell/shell.component';
import { AuthGuard} from './user/auth.guard';
import { HomeGuard } from './user/home.guard';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component'; 

import { MatProgressBarModule } from '@angular/material/progress-bar';

const rootRoutes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: 'myaccount',
        canActivate: [AuthGuard],
        data: { title: 'My Account' },
        loadChildren: () => import('./myaccount/myaccount.module').then(m => m.MyAccountModule)
      },
      {
        path: 'commentaries',
        canActivate: [AuthGuard],
        data: { title: 'Market Insights' },
        loadChildren: () => import('./commentaries/commentaries.module').then(m => m.CommentariesModule)
      },
      {
        path: 'trucking',
        canActivate: [AuthGuard],
        data: { title: 'Trucking' },
        loadChildren: () => import('./trucking/trucking.module').then(m => m.TruckingModule)
      },
      {
        path: 'producersedge',
        canActivate: [AuthGuard],
        data: { title: 'Producers Edge' },
        loadChildren: () => import('./producers-edge/producers-edge.module').then(m => m.ProducersEdgeModule)
      },
      {
        path: 'markets',
        canActivate: [AuthGuard],
        data: { title: 'Markets' },
        loadChildren: () => import('./markets/markets.module').then(m => m.MarketsModule)
      },
      {
        path: 'market-insights',
        data: { title: 'Market Insights' },
        redirectTo: '/commentaries/mkt'
      },
      {
        path: 'structured-product-quotes',
        data: { title: 'Structured Quotes' },
        redirectTo: '/commentaries/spq'
      },

      { path: '', redirectTo: '/home', pathMatch: 'full' },
    ]
  },
  { path: 'home', component: HomePage, data: { title: 'Home' }, canActivate: [HomeGuard] },
   //Wild Card Route for 404 request 
  { path: '404', component: PagenotfoundComponent }, 
  { path: '**', component: PagenotfoundComponent, data: { title: '404 - Page Not Found' } }, 
];
@NgModule({
  imports: [
    MatProgressBarModule,
    RouterModule.forRoot(rootRoutes, {})
  ],
  exports: [RouterModule],
  declarations: []
})

export class AppRoutingModule {}
