import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { of, BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { IGavilonUser, IUpdateUser, IUpdateUserCookieConsent } from '../../models/user';

declare let _hsq: any;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userUrl = '/api/myaccount/user';
  currentUser: IGavilonUser | null;

  private currentUserSource = new BehaviorSubject<IGavilonUser | null>(null);
  currentUserSource$ = this.currentUserSource.asObservable();

  constructor(private http: HttpClient, private oauthService: OAuthService) { }

  getCurrentUser(): Observable<IGavilonUser | null> {
    const savedUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUser = savedUser;
    this.currentUserSource.next(savedUser);

    if (this.currentUser) {
      return of(this.currentUser);
    }

    if (!this.oauthService.hasValidIdToken()) {
      return null;
    }

    return this.http.get<IGavilonUser>(`${this.userUrl}/additionalclaims`)
      .pipe(
        tap(data => console.table(data)),
        tap(data => {
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.currentUser = data;
          this.currentUserSource.next(data);
        }),
        catchError(this.handleError)
      );
  }

  loadCurrentUser(): void {
    if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
      this.http.get<IGavilonUser>(`${this.userUrl}/gavilonId`)
        .pipe(
          tap(data => console.table(data)),
          tap(data => {
            if (data) {
              localStorage.setItem('currentUser', JSON.stringify(data));
              this.currentUser = data;
              this.currentUserSource.next(data);
            }
          }),
          catchError(this.handleError)
        );
    }
  }

  deleteCurrentUser(userToDelete: IGavilonUser): Observable<void> {
    console.log('Deleting current user: ');
    console.log(userToDelete);
    const url = `${this.userUrl}/${userToDelete.id}`;
    return this.http.delete<void>(url).pipe(
      tap(data => console.log(data)),
      catchError(this.handleError)
    );
  }

  updateCurrentUser(userInfoToUpdate: IUpdateUser): Observable<IGavilonUser> {
    console.log('User Info to update:');
    console.log(userInfoToUpdate);
    const url = `${this.userUrl}`;
    localStorage.removeItem('currentUser');
    return this.http.post<IGavilonUser>(url, userInfoToUpdate)
      .pipe(
        tap(data => console.log(data)),
        tap(data => {
          if (data) {
            this.currentUser = data;
            this.currentUserSource.next(data);
          }
        }),
        catchError(this.handleError)
      );
  }

  updateUserCookieConsent(cookieConsent: IUpdateUserCookieConsent): Observable<IGavilonUser> {
    const url = `${this.userUrl}/cookieConsent`;
    localStorage.removeItem('currentUser');
    return this.http.post<IGavilonUser>(url, cookieConsent)
      .pipe(
        tap(data => console.log(data)),
        tap(data => {
          if (data) {
            this.currentUser = data;
            if (cookieConsent.cookiesConsent) {
              _hsq.push(['doNotTrack'], { track: true });
            } else {
              _hsq.push(['doNotTrack']);
            }

            this.currentUserSource.next(data);
          }
        }),
        catchError(this.handleError)
      );
  }

  private handleError(err) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}, body was: ${err.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }

}
