<div *ngIf="userAccounts?.length">
  <kendo-dropdownlist style="width: 100%; display: block;"
                      [data]="userAccounts"
                      [textField]="'nameDescription'"
                      [valueField]="'sourceAccountId'"
                      [value]="currentAccount"
                      (valueChange)="valueChange($event)">
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <span class="template f-s-14">{{ dataItem.nameDescription }} ({{ dataItem.sourceAccountId }})</span>
    </ng-template>
  </kendo-dropdownlist>
</div>
