import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})

export class HomeGuard  {
  constructor(private oauthService: OAuthService, private router: Router, private activateRoute: ActivatedRoute) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.oauthService.hasValidAccessToken()) {

      let test = this.oauthService.silentRefresh()
        .then(() => {
          return this.router.navigate(['/myaccount']);
        }, () => {
          return true;
        });

      return test;
    } else {
      this.router.navigate(['/myaccount']);
    }
  }
}
