import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from '../services/user.service';
import { IGavilonUser } from '../../models/user';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styles: []
})
export class UnauthorizedComponent implements OnInit, OnDestroy {
  user: IGavilonUser;
  userName = '';
  secondsRemaining = 5;
  countDown: Subscription;

  constructor(private oauthService: OAuthService, private userService: UserService) { }
  logout() {
      localStorage.removeItem('currentUser');
      this.oauthService.logOut();
  }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(
      (currentUser: IGavilonUser | null) => {
        this.user = currentUser;
        if (currentUser) {
          this.userName = `${currentUser.firstName} ${currentUser.lastName}`;
        }
      }
    );

    if (this.user && this.user.statusId !== 9) {
      this.countDown = timer(0, 1000)
        .subscribe(() => {
          --this.secondsRemaining;
          if (this.secondsRemaining === 0) {
            this.logout();
          }
        });
    }
  }

  ngOnDestroy() {
    this.countDown = null;
  }

}
