let pageMenus = [{
    img: '/images/icons/home-icon.svg',
    imgActive: '/images/icons/home-white-icon.svg',
    title: 'Home',
    url: '/myaccount',
    audience: 'all'
}, {
    icon: 'fa fa-map-marker-alt',
    title: 'Locations',
    url: '/myaccount/locations',
    audience: 'all'
}, {
    img: '/images/icons/balances-icon.svg',
    imgActive: '/images/icons/balances-white-icon.svg',
    title: 'Grain Balances',
    url: '/myaccount/grainbalances',
    audience: 'grains'
}, {
    img: '/images/icons/contracts-icon.svg',
    imgActive: '/images/icons/contracts-white-icon.svg',
    title: 'Contracts',
    url: '/myaccount/contracts',
    audience: 'grains'
}, {
    img: '/images/icons/tickets-icon.svg',
    imgActive: '/images/icons/tickets-white-icon.svg',
    title: 'Tickets',
    url: '/myaccount/tickets',
    audience: 'grains'
}, {
    img: '/images/icons/settlements-icon.svg',
    imgActive: '/images/icons/settlements-white-icon.svg',
    title: 'Settlements',
    url: '/myaccount/settlements',
    audience: 'grains'
}, {
    img: '/images/icons/pricing-icon.svg',
    imgActive: '/images/icons/pricing-white-icon.svg',
    title: 'My Pricing',
    url: '/producersedge',
    audience: 'grainPlus'
}, {
    img: '/images/icons/markets-icon.svg',
    imgActive: '/images/icons/markets-white-icon.svg',
    title: 'Markets',
    url: '/markets',
    audience: 'all'
}, {
    icon: 'far fa-lightbulb',
    title: 'Market Insights',
    url: '/commentaries/mkt',
    audience: 'all'
}, {
    icon: 'far fa-file',
    title: 'Structured Quotes',
    url: '/commentaries/spq',
    audience: 'spq'
}, {
    icon: 'far fa-lightbulb',
    title: 'Ingredients Test',
    url: '/market-insights',
    audience: 'ingredients'
},
{
    icon: 'fas fa-truck',
    title: 'Trucking',
    url: '/trucking/scale-tickets',
    audience: 'trucking'
}
];

export default pageMenus;
