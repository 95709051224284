<!-- begin #header -->
<div id="header" class="header navbar-inverse">
  <app-cookie-policy *ngIf="this.pageSettings.pageCookiePolicy" (acceptCookiePolicy)="onAcceptCookiePolicy($event)"></app-cookie-policy>
  <!-- begin navbar-header -->
  <div class="navbar-header">
    <button type="button" class="navbar-toggle" (click)="mobileSidebarToggle()" *ngIf="!this.pageSettings.pageSidebarTwo && !this.pageSettings.pageWithoutSidebar">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <a [routerLink]="['/myaccount/']" class="navbar-brand" style="padding: 8px; !important;"> <img src="/images/myViterra_Logo_Reverse.png" alt="Viterra"></a>
    <button type="button" class="navbar-toggle" (click)="mobileTopMenuToggle()" *ngIf="this.pageSettings.pageTopMenu && this.pageSettings.pageWithoutSidebar">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
  </div>
  <!-- end navbar-header -->
  <!-- begin header-nav -->
  <ul class="navbar-nav navbar-right">
    <li class="navbar-accounts">
      <app-account-list></app-account-list>
    </li>
    <li class="dropdown navbar-user" ngbDropdown>
      <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
        <div class="image image-icon bg-white text-grey-darker">
          <i class="fa fa-user"></i>
        </div>
        <span class="d-none d-md-inline">{{userName}}</span> <b class="caret"></b>
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <button (click)="editProfile()" class="dropdown-item">Edit Profile</button>
        <div class="dropdown-divider"></div>
        <button (click)="logout()" class="dropdown-item">Log Out</button>
      </div>
    </li>
    <li class="divider d-none d-md-block" *ngIf="this.pageSidebarTwo"></li>
    <li class="d-none d-md-block" *ngIf="this.pageSidebarTwo">
      <a href="javascript:;" (click)="toggleSidebarRight()" class="f-s-14">
        <i class="fa fa-th"></i>
      </a>
    </li>
  </ul>
  <!-- end header navigation right -->
</div>
<!-- end #header -->
