<!-- begin login -->
<div class="login login-with-news-feed">
  <!-- begin right-content -->
  <div class="right-content">
    <!-- begin login-header -->
    <div class="login-header text-center">
      <div class="brand">
        <img src="/images/myViterra_Logo_Reverse.png" class="img-fluid" alt="Viterra" style="padding: 2em;" />
      </div>
    </div>
    <!-- end login-header -->
    <!-- begin login-content -->
    <div class="login-content text-center">
      <div class="login-buttons">
        <button class="btn btn-viterra-green btn-block btn-lg" (click)="login()">Sign in or Sign up</button>
      </div>
      <div class="d-none d-md-block m-t-40 p-t-40">
        <hr />
        <p class="text-center text-grey-darker">
          &copy; 2023 Viterra USA Ag Holdings, LLC
        </p>
      </div>
    </div>
    <!-- end login-content -->
  </div>
  <!-- end right-container -->
  <!-- begin news-feed -->
  <div class="news-feed">
    <div class="news-image"></div>
    <div class="news-caption">
      <div class="row row-space-0 m-t-40 login-box-wrapper" style="margin-top: 0 !important;">
        <!--
          <div class="col-lg-4">
        </div>
          -->
        <div class="col-lg-4">
          <img src="/images/myViterra_Logo_Reverse.png" alt="Viterra" class="news-feed-logo" />
        </div>
      </div>
      <div class="row row-space-0 m-t-40 login-box-wrapper">
        <!--
          <div class="col-lg-4">
        </div>
          -->
        <div class="col-md-6 col-lg-6 col-xl-6">
          <div class="login-box-info box-1 text-grey-darker" style="border-radius: 12px; opacity: .90;">
            <h4 style="width: 100%; color: white !important;">
              Welcome to myViterra &#8212; formerly myGavilon
            </h4>
            <h4 style="width: 100%; color: white !important;">What’s inside:</h4>
            <ul style="width: 100%; color: white !important;">
              <li>Cash bids</li>
              <li>
                Account information
                <ul>
                  <li>Contracts</li>
                  <li>Grain balances</li>
                  <li>Tickets</li>
                  <li>Settlements</li>
                </ul>
              </li>
              <li>Market insights</li>
              <li>Structured quotes</li>
              <li>Alerts and messages from your Viterra location</li>
              <li>Futures prices</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--
  <div class="news-feed">
    <div class="news-image"></div>
    <div class="news-caption">
      <img src="/images/Viterra_Logo_White_RGB.png" alt="Viterra" class="news-feed-logo" />
      <h1 class="caption-title">Personalize your Viterra experience.</h1>
      <p class="f-w-700">
        Sign up for <span style="color: #0aaa64">MyViterra.us</span> today!
      </p>

      <div class="row row-space-0 m-t-40 login-box-wrapper">
        <div class="col-lg-6">
          <div class="login-box-info box-1 text-grey-darker" style="border-top-left-radius: 12px; border-bottom-left-radius: 12px;">
            <h2>WHAT'S INSIDE</h2>
            <ul>
              <li>Cash bids</li>
              <li>Alerts</li>
              <li>Scale tickets</li>
              <li>Contracts</li>
              <li>Grain balances</li>
              <li>Futures markets</li>
              <li>Market insight from Gavilon experts</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="login-box-info box-2 text-grey-darker" style="border-top-right-radius: 12px; border-bottom-right-radius: 12px;">
            <h2>WHAT’S NEW</h2>
            <h3>myGavilon is becoming myViterra</h3>
            <p>
              On Monday, February 27, myGavilon will be moving to my.Viterra.us. This will be the only way to access your cash bids and contract information. If you would like to learn more, please click the link to the right.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  -->
  <!-- end news-feed -->
</div>
<!-- end login -->
